import React, { Component, Suspense } from 'react';
import Loading from "../../containers/loading/Loading";
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';

export default class DisplayLottie extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // 1. create a reference for the lottie player
  }
  componentDidMount() {
    // 3. listen for player load. see lottie player repo for other events
    this.myRef.current.addEventListener('load', function (e) {
      // 4. configure the interactivity library
      create({
        mode: 'scroll',
        player: '#firstLottie',
        actions: [
          {
            visibility: [0, 0.1],
            type: 'stop',
            frames: [0],
          },
          {
            visibility: [0.1, 0.45],
            type: 'seek',
            frames: [0, 90],
          },
          {
            visibility: [0.45, 1],
            type: 'seek',
            frames: [90, 154],
          },
        ],
      });
    });
  }

  render() {
    const animationData = this.props.animationData
    // const defaultOptions = {
    //     loop: false,
    //     autoplay: true,
    //     animationData: animationData,
    //   };
    
    return (
        <Suspense fallback={<Loading/>}>
          {/* To override default onClick pause by Lottie */}
          <div onClick='null'>
          <lottie-player
          ref={this.myRef} // 2. set the reference for the player
          id="firstLottie"
          mode="normal"
          src={animationData}
        ></lottie-player>
          </div>
        </Suspense>
    );
  }
}
